<template>
  <div class="ellipsis"><div class="middle">.</div></div>
</template>

<script>
export default {
  name: "IconEllipsis",
};
</script>

<style lang="scss">
.ellipsis {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  padding: 0;
  margin: 0;
  white-space: nowrap;
  color: var(--navy) !important;
  font-size: 25px;

  &:before,
  &:after {
    content: ".";
    display: inline-block;
  }

  &:before {
    animation: ellipsis 1.5s $ease-2 infinite;
  }

  .middle {
    display: inline-block;
    animation: ellipsis 1.5s $ease-2 0.1s infinite;
  }

  &:after {
    animation: ellipsis 1.5s $ease-2 0.2s infinite;
  }

  @keyframes ellipsis {
    0% {
      transform: translateY(0);
    }

    10% {
      transform: translateY(-0.6ex);
    }

    20% {
      transform: translateY(0);
    }
  }
}
</style>
