<template>
  <div class="ScrollableContainer">
    <div class="EndGame">
      <Transition name="fade" v-if="false">
        <div class="TEMPWinner">
          <header>{{ $t('player-winnerid-wins', [winnerId]) }}</header>
          <div class="TEMPWinner__body">
            <div class="TEMPWinner__circle">
              <div class="TEMPWinner__avatar">
                <Avatar :avatar-id="playerAvatars.get(winnerId)" />
              </div>
              <!-- <div class="scorecard">
                      <div class="scorecard__score">{{ scores[winnerId] }}</div>
                      <div class="scorecard__text">Points</div>
                    </div> -->
            </div>
          </div>
        </div>
      </Transition>
      <div class="inner">
        <Header class="clear">
          <template v-slot:center>
            <div />
          </template>
          <template v-slot:right>
            <div class="header-button">
              <Button class="primary" @click="goPlayAgain" @mousedown="playSound(soundConsts.PLAY_AGAIN_BUTTON)">{{
                $t('play-again') }}</Button>
            </div>
          </template>
        </Header>

        <div class="EndGame__container">
          <div class="EndGame__intro">
            <h2>{{ $t('great-game') }}</h2>
            <div class="winner">
              <div class="avatar-container">
                <Avatar class="avatar" :avatar-id="playerAvatars.get(winnerId)" />
                <div class="scorecard">
                  <div class="scorecard__score">{{ scores[winnerId] }}</div>
                </div>
              </div>
              <p>{{ $t('player-winnerid-wins', [winnerId]) }}</p>
            </div>

          </div>
          <ResourcesCard v-if="hotTips.length > 0" class="hottip-link" @click="openHotTips"
            @mousedown="playSound(soundConsts.CTA_BUTTON)" hottip clickable hasArrow>
            {{ $t('your-stories') }} {{ $t('and-hot-tips') }}
          </ResourcesCard>

          <div class="EndGame__resources">
            <h2>{{ $t('find-out-more') }}</h2>
          </div>

          <div class="EndGame__tidbits">
            <div class="text-link" @click="goToSurvey('end of game screen', 'Take a quick end of game survey!')"
              @mousedown="playSound(soundConsts.CTA_BUTTON)">Take a quick end of
              game survey!</div>
          </div>
        </div>
      </div>

      <!-- END OF GAME SURVEY MODAL  -->
      <DrawerModal ref="survey">
        <template v-slot:header>
          <div class="modal-header">
            <img class="modal-image" src="/assets/ui_elements/pink-flower.png" />
            <h2>{{ $t('help-us-make-the-game-better-by-taking-this-end-of-game-survey') }}</h2>
          </div>
        </template>
        <template v-slot:body>
          <Button class="primary" @click="goToSurvey('end of game modal', 'Take the Survey')"
            @mousedown="playSound(soundConsts.CTA_BUTTON)">{{ $t('take-the-survey') }}</Button>
        </template>
      </DrawerModal>

      <!-- HOT TIPS MODAL  -->
      <FullScreenModal class=" HotTipsModal" ref="hotTipsScreen" noexit hideStickers>
        <template v-slot:header>
          <div class="FullScreenModal__header">
            <div class="FullScreenModal__header-back-button"><img @click="closeHotTips" class=""
                src="/assets/ui_elements/arrowBack.svg" />
            </div>
            <h1>{{ $t('hot-tips') }}</h1>
            <span class="HotTips__header-spacer" />
          </div>
        </template>
        <template v-slot:body>

          <div class="HotTips__content">
            <template v-for="item in colorAssignedHotTips" :key="item.roundNumber">
              <SubmitScenarioResource v-if="item.roundNumber == 3" moduleName="HotTipsPage" />
              <HotTipCard :round-number="item.roundNumber" :scenario="item.prompt" :tip="item.hottip"
                :color="item.color" />
            </template>
          </div>

        </template>
      </FullScreenModal>

    </div>

    <DynamicResources />

    <div class="EndGame__footer">
      <img @click="logoClick" class="EndGame__footer-logo" src="/assets/logos/logo-likely-story.svg" />
      <div class="EndGame__footer-right-content">
        <div class="EndGame__footer-socials">
          <img src="/assets/social/tiktok.svg" @click="openLink('https://www.tiktok.com/@likelystorygame')" />
          <img src="/assets/social/instagram.svg" @click="openLink('https://www.instagram.com/likelystorygame/')" />
        </div>
        <p class="EndGame__footer-copyright">{{ $t('c-likely-story-the-game-2021') }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import Avatar from "../Avatar";
import Button from "../buttons/Button";
import DrawerModal from "../modal/drawer-modal";
import Header from "../header";
import { audioPlayer } from "/src/soundfx/audioPlayer.js";
import { LANDING_PAGE_URL } from "/src/consts/urls";
import DynamicResources from "../../DynamicResources";
import ResourcesCard from "../../ResourcesCard";
import FullScreenModal from '/src/components/modal/full-screen-modal'
import HotTipCard from "./HotTipCard";
import SubmitScenarioResource from "../Resources/SubmitScenarioResource.vue";

export default {
  name: "EndGame",
  mixins: [audioPlayer],
  components: {
    Avatar,
    Button,
    DrawerModal,
    Header,
    DynamicResources,
    ResourcesCard,
    FullScreenModal,
    HotTipCard,
    SubmitScenarioResource
  },
  data: () => ({
    showWinner: false,
    hotTips: [],
  }),
  props: {
    winnerId: { type: Number, default: 2 },
    playerAvatars: Object,
    players: Array,
    scores: Object,
    roomId: String,
  },
  methods: {
    openLink: function (url) {
      window.open(url)
    },
    logoClick: function () {
      // GTM Submission
      this.$gtm.trackEvent({
        event: 'nav_click',
        module_name: 'End Game Footer',
        nav_type: 'footer logo',
        link_url: LANDING_PAGE_URL,
      });
      // Go home
      window.location.href = LANDING_PAGE_URL;
    }
    ,
    goToSurvey: function (module_name, click_text) {

      // get survey link based on singlePlayer url param
      const singlePlayer = this.$route.query.singlePlayer;
      var SURVEY_LINK = "https://0tu04830fjv.typeform.com/multiplayer"
      if (singlePlayer === 'true') {
        SURVEY_LINK = 'https://0tu04830fjv.typeform.com/singleplayer'
      }

      this.$gtm.trackEvent({
        event: 'survey_link_click',
        module_name,
        click_text,
        link_url: SURVEY_LINK,
      });
      window.open(SURVEY_LINK);
    },
    goPlayAgain: function () {
      // Google Tag Manager Event
      this.$gtm.trackEvent({
        event: 'play_again_click',
        click_text: 'Play again',
        link_url: LANDING_PAGE_URL
      });

      this.$emit('mainPage');
    },
    openHotTips: function () {
      this.$refs.hotTipsScreen.openModal()
    },
    closeHotTips: function () {
      this.$refs.hotTipsScreen.closeModal()
    },
    obtainHotTipsFromStorage: function () {
      const hotTips = localStorage.getItem(`${this.roomId}-hottips`)
      if (!hotTips) {
        return
      }
      const hotTipsObj = JSON.parse(hotTips)
      delete hotTipsObj.expiry
      const index = Object.keys(hotTipsObj).sort()

      const sortedArrayOfHotTips = index.map((v) => {
        return { roundNumber: v, hottip: hotTipsObj[v].hottip, prompt: hotTipsObj[v].prompt }
      })
      this.hotTips = sortedArrayOfHotTips
    },
  },
  computed: {
    colorAssignedHotTips() {
      const colors = ["blue", "pink", "green"]
      let colorPtr = 0
      let i = 0
      const assignedHotTips = []
      while (i < this.hotTips.length) {
        assignedHotTips[i] = this.hotTips[i]
        assignedHotTips[i].color = colors[colorPtr]

        colorPtr++
        i++

        if (colorPtr >= colors.length) {
          colorPtr = 0
        }
      }
      return assignedHotTips
    },
    watch: {
      roomId(roomId) {
        if (roomId) {
          this.obtainHotTipsFromStorage()
        }
      },
    }
  },
  beforeCreate() {
    this.$gtm.trackEvent({
      event: 'dataLayer_initialized',
      page_name: 'end game',
    });
  },
  beforeUnmount() {
    this.stopBackgroundMusic();
  },
  mounted() {
    this.obtainHotTipsFromStorage()

    if (this.winnerId != null) {
      this.playBackgroundMusic(this.soundConsts.BG_MUSIC_WAITING_ROOM);
      this.playSound(this.soundConsts.WINNER_CELEBRATION);
      this.showWinner = true;

      // Google Tag Manager Event
      this.$gtm.trackEvent({
        event: 'game_complete',
        number_players: this.players.length,
      });
    }

    let time = 4000;
    setTimeout(() => {
      this.showWinner = false;

      if (this.winnerId != null) {
        this.$refs.survey.openModal();
      }
    }, time);

    this.$gtm.trackEvent({
      event: 'virtual_page_view',
      page_name: 'endgame',
      page_url: window.location.href
    })
  }
};
</script>

<style lang="scss" scoped>
.header-button :deep(button) {
  font-size: 16px;
  padding: 9.5px 22px;
  white-space: nowrap;

  @media screen and (min-width:701px) {
    font-size: 20px;
    padding: 10.5px 26px;

  }
}

:deep(.ResourcesCard.hottip-link) {
  margin-bottom: 45px;
  width: 100%;
  max-width: 400px;

  @media only screen and (min-width: 701px) {
    margin-bottom: 55px;
  }
}

.ScrollableContainer {
  height: 100vh;
  width: 100vw;
  overflow-y: auto;
}

.EndGame {
  display: flex;
  flex-direction: column;
  font-family: 'rocgrotesk', sans-serif;
  overflow: hidden;
  background-color: var(--light-cream);

  .inner {
    overflow-y: auto;
    position: relative;
    -webkit-overflow-scrolling: touch;
    overscroll-behavior: auto;

    @media only screen and (min-width: 701px) {
      overflow: hidden;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    padding: var(--spacing);
    align-items: center;

    @media only screen and (min-width: 701px) {
      padding: var(--spacing-medium);
    }
  }

  &__intro {
    font-size: 16px;
    text-align: center;
    margin: 0 auto;
    margin-bottom: var(--spacing-large);
    color: var(--navy);

    @media only screen and (min-width: 701px) {
      font-size: 24px;
    }

    h2 {
      font-family: 'cheee';
      font-size: 40px;
      font-weight: 520;
      margin-bottom: 1.75rem;

      @media only screen and (min-width: 701px) {
        font-size: 58px;
        margin-bottom: 2.5rem;
        margin-top: 2.5rem;
      }
    }

    .winner {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-left: 15px;

      @media only screen and (min-width: 701px) {
        margin-left: 25px;
      }


      .avatar-container {
        position: relative;

        .scorecard {
          position: absolute;
          bottom: 0;
          right: 0;
          transform: rotate(11.79deg) translate(40%, -10%);
          background-color: var(--green);
          border-radius: 12px;
          font-weight: 700;
          font-size: 24px;
          line-height: 1.3;
          padding: 10px 8px;
          width: 30px;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;

          @media only screen and (min-width: 701px) {
            font-size: 26px;
            height: 35px;
            width: 35px;
            padding: 11px 8.5px;
            border-radius: 14px;
          }
        }

        .scorecard__score {
          margin-top: 2px;
        }
      }

      .avatar {
        height: 60px;
        width: 60px;

        @media only screen and (min-width: 701px) {
          height: 80px;
          width: 80px;
        }
      }

      p {
        color: var(--navy);
        font-size: 20px;
        font-weight: 700;
        line-height: 1.3;
        margin-left: 33px;

        @media only screen and (min-width: 701px) {
          font-size: 26px;
          margin-left: 40px;
        }
      }
    }
  }

  &__resources {
    order: 3;

    h2 {
      font-size: 24px;
      font-weight: 750;
      line-height: 1.3;
      text-align: center;
      color: var(--navy);
      margin-bottom: -7px;

      @media only screen and (min-width: 701px) {
        font-size: 28px;
        margin-bottom: -12px;

      }
    }
  }

  &__tidbits {
    order: 2;
    font-size: 16px;
    text-align: center;
    margin-bottom: var(--spacing-large);

    @media only screen and (min-width: 701px) {
      width: 100%;
      max-width: 400px;
      margin: 0 auto var(--spacing-large);
    }

    .text-link {
      color: var(--navy);
      font-weight: 700;
      font-size: 18px;
      line-height: 1.3;
      text-decoration: underline;
      cursor: pointer;


      @media only screen and (min-width: 701px) {
        font-size: 23px;
      }
    }
  }

  &__footer {
    padding: var(--spacing);
    background-color: var(--dark-cream);
    display: flex;
    justify-content: space-between;
    align-items: center;

    img {
      cursor: pointer;
    }

    &-logo {
      width: 64px;

      @media only screen and (min-width: 701px) {
        width: 85px;
      }
    }

    &-right-content {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }

    &-socials img {
      height: 18px;
      margin-bottom: 16px;

      &:not(:last-child) {
        margin-right: 17px;
      }

      @media only screen and (min-width: 701px) {
        height: 24px;


        &:not(:last-child) {
          margin-right: 22px;
        }
      }
    }

    &-copyright {
      color: var(--navy);
      font-weight: 500;
      font-size: 12px;
      line-height: 1.2;
      letter-spacing: 0.01em;

      @media only screen and (min-width: 701px) {
        font-size: 14px;
      }
    }

  }

  // Hot Tips Modal
  .FullScreenModal__header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    margin-top: 21px;

    h1 {
      font-size: 32px;
      font-family: 'cheee';
      font-weight: 520;
      font-family: 32px;
      line-height: 1.4;
      letter-spacing: .05em;
      color: var(--navy);
    }

    &-back-button {
      padding: 5px;
      cursor: pointer;
      margin-left: -5px;

      img {
        height: 21px;
        width: 21px;
      }
    }

    @media only screen and (min-width: 701px) {
      h1 {
        font-size: 46px;
      }

      &-back-button {
        margin-left: 0;
        padding: 10px;

        img {
          height: 35px;
          width: 35px;
        }
      }
    }
  }

  .HotTipsModal :deep(.modal__body) {
    padding: 39px;
    padding-top: 17px;

    @media only screen and (min-width: 701px) {
      padding-top: 24px;
    }
  }

  :deep(.modal__footer) {
    margin-top: 0;
  }

  // End of Game Survey Modal
  .modal-header {
    color: var(--navy);
    font-size: 22px;
    font-weight: 700;
    text-align: center;
    line-height: 1.31;

    h2 {
      padding: 40px 20px 30px;
    }
  }

  .modal-image {
    width: 123px;
    height: 99px;
  }
}

// TEMP
.TEMPWinner {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  height: var(--app-height);
  background-color: var(--navy);
  background-image: url('/assets/backgrounds/bg-navy.png');
  background-size: cover;
  background-repeat: no-repeat;
  color: var(--white);
  padding: var(--spacing-medium);
  z-index: 100;

  header {
    font-family: 'cheee';
    font-size: 50px;
    line-height: 1.2;
    margin-bottom: var(--spacing);
    text-align: center;

    @media only screen and (min-width: 701px) {
      font-size: 72px;
      margin-bottom: var(--spacing-medium);
    }
  }

  &__body {
    font-family: var(--font-body);
    font-size: 18px;
    font-weight: 700;
  }

  &__circle {
    position: relative;
    background-color: rgba(255, 255, 255, 0.1);
    padding: var(--spacing-medium);
    border-radius: 50%;
  }

  &__avatar {
    width: 80px;
    height: 80px;

    @media only screen and (min-width: 701px) {
      width: 108px;
      height: 108px;
    }

    img {
      width: 100%;
      max-width: 100%;
      max-height: 100%;
    }
  }

  .scorecard {
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: var(--white);
    color: var(--navy);
    text-align: center;
    padding: var(--spacing-small);
    border-radius: var(--radius-medium);
    transform: translate(25%, 50%) rotate(15deg);

    &__score {
      font-size: 32px;
    }

    &__text {
      font-size: 12px;
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.16s $ease-3 0.33s;
  /* transform: scale(1); */
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  /* transform: scale(1.15); */
}
</style>
