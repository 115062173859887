<template>
  <div class="drawerModal" v-if="show">
    <div class="drawerModal__backdrop" @click="closeModal()" />

    <div class="drawerModal__dialog">
      <div class="drawerModal__header">
        <slot name="header" />
        <!-- <button type="button" class="drawerModal__close" @click="closeModal()">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512">
              <path
                fill="currentColor"
                d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
              ></path>
            </svg>
          </button> -->
      </div>

      <div class="drawerModal__body">
        <slot name="body" />
      </div>

      <div class="drawerModal__footer">
        <slot name="footer" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Drawer Modal",
  data() {
    return {
      show: false
    };
  },
  methods: {
    closeModal() {
      this.show = false;
      document.querySelector('body').classList.remove('overflow-hidden');
    },
    openModal() {
      this.show = true;
      document.querySelector('body').classList.add('overflow-hidden');
    }
  }
};
</script>

<style lang="scss" scoped>
.drawerModal {
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9;

  &__backdrop {
    background-color: var(--navy);
    opacity: 0.5;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
  }

  &__dialog {
    display: flex;
    position: absolute;
    bottom: 0;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    background-color: var(--light-cream);
    overflow: hidden;
    border-top-left-radius: var(--radius-medium);
    border-top-right-radius: var(--radius-medium);
    z-index: 2;

    @media only screen and (min-width: 701px) {
      position: relative;
      max-width: 675px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: var(--radius-medium);
    }
  }

  &__close {
    width: 30px;
    height: 30px;
  }

  &__header {
    padding: 40px 20px 10px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    @media only screen and (min-width: 701px) {
      padding-top: 50px;
      font-size: 30px;
    }

    &::before {
      content: '';
      position: absolute;
      width: 50px;
      height: 3px;
      top: var(--spacing-small);
      left: 50%;
      opacity: 0.2;
      background-color: var(--navy);
      transform: translateX(-50%);

      @media only screen and (min-width: 701px) {
        display: none;
      }
    }
  }

  &__body {
    padding: 10px 20px 10px;
    overflow-x: hidden;
    overflow-y: auto;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    align-items: stretch;
    -webkit-overflow-scrolling: touch;

    @media only screen and (min-width: 701px) {
      align-items: center;
      flex-grow: initial;
      padding: 0;
      overflow: initial;
    }
  }

  &__footer {
    padding: 0 20px 20px;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>