<template>
  <img v-if="avatarId" :src="url" :alt="alt" />
  <IconEllipsis v-else />
</template>

<script>
import IconEllipsis from './icons/IconEllipsis';

export default {
  name: "Avatar",
  components: { IconEllipsis },
  props: {
    avatarId: Number,
  },
  computed: {
    alt() {
      if (this.avatarId) {
        return this.$cms.avatars[this.avatarId].alt;
      }
      return null;
    },
    url() {
      if (this.avatarId) {
        return this.$cms.avatars[this.avatarId].url;
      }
      return "/assets/avatars/0.svg";
    },
  },
};
</script>
