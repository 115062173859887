<template>
    <div class="HotTipCard">
        <div class="HotTipCard__top" :class="color">
            <h3 class="HotTipCard__round-number">{{ $t('round-roundnumber', [roundNumber]) }}</h3>
            <p class="HotTipCard__scenario">{{ scenario }}</p>
        </div>
        <div class="HotTipCard__bottom">
            <Sticker image="/assets/ui_elements/hotTip.svg" class="sticker-hottip" :delay="0.1" />
            <p class="HotTipCard__tip">{{ tip }}</p>
        </div>
    </div>
</template>
<script>
import Sticker from "/src/components/stickers/Sticker";

export default {
    name: "HotTipCard",
    components: {
        Sticker
    },
    props: {
        roundNumber: Number,
        scenario: String,
        tip: String,
        color: String,
    }
}
</script>
<style lang="scss" scoped>
.HotTipCard {
    color: var(--navy);
    font-family: 'rocgrotesk';
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0.01em;
    text-align: center;
    padding-bottom: 31px;
    max-width: 500px;

    @media only screen and (min-width: 701px) {
        font-size: 23px;
    }

    &__top {
        border: 3px solid var(--navy);
        border-bottom: 0px;
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;
        background-color: var(--forest-200);
        padding: 27px;

        @media only screen and (min-width: 701px) {
            padding: 34px;
        }
    }

    &__scenario {
        line-height: 1.2;
    }

    &__round-number {
        font-family: 'cheee';
        font-weight: 520;
        margin-bottom: 4px;

        @media only screen and (min-width: 701px) {
            margin-bottom: 8px;

        }
    }

    &__bottom {
        background-color: var(--white);
        position: relative;
        border: 3px solid var(--navy);
        border-top: 0px;
        border-bottom-left-radius: 16px;
        border-bottom-right-radius: 16px;
        padding: 31px 26px;

        @media only screen and (min-width: 701px) {
            padding: 39px 32px;
        }
    }

    &__tip {
        line-height: 1.3;
    }

    .sticker-hottip {
        position: absolute;
        width: 138px;
        height: auto;
        top: -30px;
        left: -40px;
        transform: translate3d(0, 0, 0);

        @media only screen and (min-width: 701px) {
            width: 174px;
            top: -37px;
            left: -50px;
        }
    }

    & .blue {
        background-color: var(--forest-200);
    }

    & .pink {
        background-color: var(--medium-pink);
    }

    & .green {
        background-color: var(--green-200);
    }

}
</style>